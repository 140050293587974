/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { Heading, Link, Text, Box } from '../components/ui'

const postLink = post => `/blog/${post.slug}`

const MdxBlogExcerpt = ({ post }) => (
  <Text>
    {post.excerpt} <Link to={postLink(post)}>read more</Link>
  </Text>
)

const BlogExcerpt = props => {
  if (props.post.type === 'BlogPostMdx') {
    return <MdxBlogExcerpt {...props} />
  }
  return null
}

const BlogIndex = props => {
  const posts = props.data.allBlogPost.nodes

  return (
    <Layout>
      <SEO title="Blog" />
      <Box variant="layout.padding">
        {posts.map(post => {
          return (
            <div key={post.slug}>
              <Link to={postLink(post)}>
                <Heading>{post.title}</Heading>
              </Link>
              <Text>{post.date}</Text>
              <BlogExcerpt post={post} />
            </div>
          )
        })}
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query AllBlogPosts {
    allBlogPost(
      filter: { published: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
        excerpt
        date(formatString: "MMMM Do YYYY")
        type
      }
    }
  }
`

export default BlogIndex
